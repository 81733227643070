import * as React from 'react';
import styled from "styled-components";
 
interface NavMenuProps {}
interface NavMenuState {}

//#region Styled Components
const FooterMainContainer = styled.footer`
    background: ${({ theme: { colors } }) => colors.primary.main};
    color: ${({ theme: { colors } }) => colors.greys.dark};
    border-bottom: 8px solid ${({ theme: { colors } }) => colors.primary.main};
    
    @media ${({ theme: { deviceSizes } }) => deviceSizes.mobile} { 
        display:block;
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.print} { 
        display:none;
    }
`;

const Footerbar = styled.div`   
    width: 100%; font-family: 'foundrysterling-bookregular';
    max-width: 1900px;
    padding: 5px 0 ;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;flex-direction: column;
    flex-wrap: wrap;
    & p{
        color:${({ theme: { colors } }) => colors.white};font-size: 18px;letter-spacing: 2px;
        text-transform: uppercase; 
    }
    & p.small{ font-size:14px; }
 
    @media ${({ theme: { deviceSizes } }) => deviceSizes.mobile} { 
        width: auto;
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.tablet} { 
        width: 100%;
    }
`;

const NavigationList = styled.ul`
    margin:16px 0;text-align: center;
`;

const NavigationItem = styled.li`
    display: inline;
`;
const NavigationLink = styled.a`
    display: inline-block;
    padding: .5em 1.5em; color:${({ theme: { colors } }) => colors.greys.xdark}; 
    font-family:${({ theme: { fonts } }) => fonts.alt}, 'sans-serif'; 
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;
    &:hover{
        background: ${({ theme: { colors } }) => colors.primary.main};color:${({ theme: { colors } }) => colors.white}; 
    }
`;

const PrivacyLink = styled.a`
    display: inline-block;
    padding: 0 1.5em; color:${({ theme: { colors } }) => colors.white}; 
    font-family:${({ theme: { fonts } }) => fonts.alt}, 'sans-serif'; 
    text-decoration: none;
    text-transform: uppercase;
 
    font-size: 18px;
    letter-spacing: 1px;
    &:hover{
        background: ${({ theme: { colors } }) => colors.primary.main};color:${({ theme: { colors } }) => colors.white}; 
    }
`;
//#endregion
 
export class Footer extends React.Component<NavMenuProps, NavMenuState> {

    constructor (props: any) {
        super(props);
        this.state = { };
    }

    render() {
        var d = new Date();
        return (
            <>
                <FooterMainContainer>
                    <Footerbar>
                        <p style={{textAlign:`center`}}>ALAS, 311 South Wacker Drive, Suite 5700, Chicago, IL 60606 (p)312.697.6900</p>
                        <NavigationList style={{padding:`0`, margin:`0`}}>
                                <NavigationItem>
                                    <NavigationLink href="https://www.linkedin.com/company/alas-ltd/" target='_blank'>
                                        <img src={require(`../../assets/linkedin.png`)} alt='Linkedin' />
                                    </NavigationLink>
                                </NavigationItem>                             
                        </NavigationList>
                        <p style={{ textAlign: `center`, margin: `0` }}>Copyright © {d.getFullYear()} by Attorneys’ Liability Assurance Society (ALAS). All Rights Reserved.</p>  
                        <PrivacyLink href="https://www.alas.com/privacypolicy.aspx" target="_blank">ALAS LTD. PRIVACY POLICY {d.getFullYear()}</PrivacyLink>
                    </Footerbar>
                </FooterMainContainer>
            </>
        );
    }
}
