import { CustomError } from '../../models/Error';
import { logException } from './TelemetryService';

export function handleApiErrors(error:any, errorTitle:string): CustomError {
    let errorMessage: string = '';
    if ((error === undefined || error.response === undefined) === false) {
        errorMessage = `${error.response.status} ${error.response.statusText}`;
    }

    if (error.response) {
        if(showErrorPage(error.response.status)){
            let pageError:CustomError = {
                errorTitle: errorTitle,
                statusCode: error.response.status,
                errorMessage: error.response.statusText,
                showErrorPage: true
            };
            logException(errorTitle, errorMessage , 4 );
            return pageError;
        }else{
            let pageError:CustomError = {
                errorTitle: errorTitle,
                statusCode: error.response.status,
                errorMessage: error.response.statusText,
                showErrorPage: false
            };
            logException(errorTitle, errorMessage, 3 );
            return pageError;
        }
    } 
    return new CustomError();
}

export function showErrorPage(statusCode:number):boolean {
    let showPage:boolean = false;
    switch (statusCode) {
        case 502:
        case 503:
            showPage = true;
        break;
      default:
        return showPage;
    }
    return showPage;
}
 